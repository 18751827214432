<template>
	<div class="pui-form">
		<incident-modals :modelName="modelName"></incident-modals>
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<incident-form-header :modelPk="modelPk"></incident-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4 mt-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
				<v-tab :key="0" :href="'#maintab'">{{ $t('form.incident.maintab') }}</v-tab>
				<v-tab :key="1" :href="'#documents'" v-if="!isCreatingElement && showDocuments">{{ $t('pui9.documents.tab') }}</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'maintab'">
					<v-row class="pui-form-layout">
						<v-col cols="12">
							<v-row dense>
								<v-col cols="3">
									<pui-select
										:id="`portname-${modelName}`"
										:attach="`portname-${modelName}`"
										:label="this.$t('form.incident.portname')"
										toplabel
										clearable
										required
										:disabled="!isCreatingElement"
										v-model="model"
										modelName="port"
										:itemsToSelect="[{ id: model.portid }]"
										:modelFormMapping="{ id: 'portid' }"
										itemValue="id"
										itemText="portname"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="2">
									<pui-select
										:id="`status-${modelName}`"
										:attach="`status-${modelName}`"
										:label="$t('form.incident.status')"
										toplabel
										clearable
										required
										disabled
										v-model="model"
										modelName="incidentstatus"
										:itemsToSelect="[{ id: model.statusid }]"
										:modelFormMapping="{ id: 'statusid' }"
										itemValue="id"
										itemText="name"
										reactive
									></pui-select>
								</v-col>
								<v-col cols="7">
									<pui-select
										:id="`visitid-${modelName}`"
										:attach="`visitid-${modelName}`"
										:label="$t('form.incident.portcallnumber')"
										toplabel
										clearable
										:disabled="!model.portid || !isCreatingElement"
										v-model="model"
										modelName="visit"
										:itemsToSelect="[{ id: model.visitid }]"
										:modelFormMapping="{ id: 'visitid' }"
										:itemValue="['id']"
										:itemText="
											(item) =>
												`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
													item.eta,
													'DD/MM/YYYY HH:mm'
												)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.etd, 'DD/MM/YYYY HH:mm')} - ${item.status}`
										"
										:fixedFilter="portidFixedFilter"
										reactive
									></pui-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12">
									<pui-text-area
										:id="`description-${modelName}`"
										v-model="model.description"
										:label="$t('form.incident.description')"
										:disabled="formDisabled"
										required
										toplabel
										maxlength="300"
									></pui-text-area>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="5">
									<pui-text-area
										:id="`newdetails-${modelName}`"
										v-model="model.newdetails"
										:label="$t('form.incident.newdetails')"
										:disabled="formDisabled"
										toplabel
										maxlength="1000"
									></pui-text-area>
								</v-col>
								<v-col cols="7">
									<pui-text-area
										:id="`details-${modelName}`"
										v-model="model.details"
										:label="$t('form.incident.detailHistory')"
										disabled
										toplabel
										maxlength="1000"
									></pui-text-area>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
				<v-tab-item :key="1" :value="`documents`" v-if="!isCreatingElement && showDocuments">
					<pui-master-detail componentName="PuiDocumentGrid" :parentModelName="modelName" :parentPk="pk"></pui-master-detail>
				</v-tab-item>
			</v-tabs-items>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import incidentActions from './IncidentActions';
import incidentModals from './IncidentModals.vue';
import IncidentFormHeader from './IncidentFormHeader.vue';

export default {
	name: 'incident-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'incident-modals': incidentModals,
		IncidentFormHeader
	},
	data() {
		return {
			modelName: 'incident',
			actions: incidentActions.formactions,
			openedStatus: 1,
			closedStatus: 2,
			portidFixedFilter: null,
			finalizedStatusCode: 'FI',
			visitStatus: []
		};
	},
	methods: {
		afterGetData() {
			this.getVisitStatusByPA();
			if (this.isCreatingElement) {
				this.model.statusid = this.openedStatus;
			} else {
				this.formDisabled = this.model.statusid === this.closedStatus ? true : false;
			}
		},
		getVisitStatusByPA() {
			const opts = {
				model: 'visitstatuspa',
				filter: {
					groupOp: 'and',
					groups: [],
					rules: []
				}
			};
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				if (response.data.data[0]) {
					const finalizedStatusInfo = response.data.data.find((element) => element.visitstatuscode === this.finalizedStatusCode);
					let finishedStatusOrder = finalizedStatusInfo.orderby;
					response.data.data.forEach((element) => {
						if (element.orderby < finishedStatusOrder) {
							this.visitStatus.push(element.visitstatuscode);
						}
					});
				}
			});
		}
	},
	computed: {},
	created() {},
	watch: {
		'model.portid'(newVal) {
			if (newVal != null && this.isCreatingElement) {
				this.portidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'portid', op: 'eq', data: newVal },
						{ field: 'statusid', op: 'in', data: this.visitStatus }
					]
				};
			} else {
				this.portidFixedFilter = null;
			}
		}
	}
};
</script>
